import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import clickOutside from "./use/click-outside";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      location.href = process.env.VUE_APP_NEW_ADMIN_SITE_ENTRANCE as string;
      return;
    }
    return Promise.reject(error);
  }
);

const app = createApp(App)
  .use(router)
  .use(store)
  .directive("click-outside", clickOutside);

app.mount("body");
