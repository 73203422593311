import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("./views/Dashboard/Index.vue"),
  },
  {
    path: "/news",
    component: () => import("./views/News/Index.vue"),
  },
  {
    path: "/exchange",
    component: () => import("./views/Exchange.vue"),
  },
  {
    path: "/teasers",
    component: () => import("./views/Teasers/Index.vue"),
  },
  {
    path: "/teasers-settings",
    component: () => import("./views/Teasers/TeasersSettings.vue"),
  },
  {
    path: "/offers",
    component: () => import("./views/Offers/Index.vue"),
  },
  {
    path: "/advertisers",
    component: () => import("./views/Advertisers/Index.vue"),
  },
  {
    path: "/faq",
    component: () => import("./views/Faq/Index.vue"),
  },
  {
    path: "/affiliates",
    component: () => import("./views/Affiliates/Index.vue"),
  },
  {
    path: "/notifications",
    component: () => import("./views/Notifications/Index.vue"),
  },
  {
    path: "/payouts",
    component: () => import("./views/Payouts/Index.vue"),
  },
  {
    path: "/profile",
    component: () => import("./views/Profile/Index.vue"),
  },
  {
    path: "/users",
    component: () => import("./views/Users/Index.vue"),
  },
  {
    path: "/operative-report",
    component: () => import("./views/OperativeReport.vue"),
  },
  {
    path: "/finance-report",
    component: () => import("./views/FinanceReport/Index.vue"),
  },
  {
    path: "/page-settings",
    component: () => import("./views/PageSettings.vue"),
  },
  {
    path: "/push",
    component: () => import("./views/OneSignal/Index.vue"),
  },
  {
    path: "/log-in",
    component: () => import("./views/LogIn.vue"),
  },
  {
    path: "/:path(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
