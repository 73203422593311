const registry: any[] = [];

export default {
  mounted(el: any, binding: any) {
    if (registry.length === 0) {
      document.addEventListener("click", onClick);
    }
    if (typeof binding.value !== "function") {
      throw new Error("binding value must be a function");
    }
    registry.push({ el, binding });
  },
  beforeUnmount(el: any) {
    registry.splice(
      registry.findIndex((item) => item.el === el),
      1
    );
    if (registry.length === 0) {
      document.removeEventListener("click", onClick);
    }
  },
};

function onClick(e: any) {
  for (const { el, binding } of registry) {
    if (e.target !== el && !el.contains(e.target)) {
      binding.value();
    }
  }
}
