import { createStore } from "vuex";

interface User {
  id: null | number;
  name: string;
  email: string;
  telegram: string;
  photo: string;
  country: string;
  role: string;
  timezoneoffset: string;
}

interface DataModel {
  user: User | null;
}

export default createStore<DataModel>({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user: User) {
      state.user = user;
    },
  },
  actions: {},
  modules: {},
});
